import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';

import Link from './elements/link';
import theme from '../utils/themeconstants';

import TmrowLogo from '../images/svg/tomorrow_logo_white_withsignet.svg';
import TmrowLogoBlack from '../images/svg/tomorrow_logo_black_withsignet.svg';

const TopHeader = styled.header`
  height: ${theme.dim.headerHeight};
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background: transparent;
  z-index: 500;
  display:flex;
  align-items: center;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      position: absolute;
    }
`;

const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 4em;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    padding: 0 2em;
    }
`;

const Navigation = styled.nav`
  display: block;
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
  /* li {
    display: flex;
    justify-content: center;
  } */
  li a {
    margin: 0 0 0 2em;
    font-weight: 600;
  }
`;

const SubNav = styled(List)`
  position: absolute;
  right: 0;
  padding: 1rem 0 0 0;
  /* to reinstate on hover expand of subnav, replace 0 with 50px */
  height: ${props => (props.expanded ? '0px' : '0px')};
  height: ${props => (props.parent && '50px !important')};
  overflow: hidden;
  li {
    display: flex;
    justify-content: center;
  }
  li a{
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 0 2em;
    font-size: 16px;
    opacity: 0;
    /* to reinstate on hover expand of subnav, replace 0 with 1 */
    opacity: ${props => (props.expanded ? '0' : '0')};
    opacity: ${props => (props.parent && '1 !important')};
    transition: opacity 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  }
`;

const NavSeparator = styled.hr`
  position: relative;
  margin: 0;
  width: 100%;
  border: 0;
  height: 2px;
  top: 38px;
  border-radius: 2px;
  background: ${props => (props.dark
    ? 'linear-gradient(to left, rgba(37,32,64,0.3), rgba(37,32,64,0))'
    : 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))')} ;
  /* to reinstate on hover expand of subnav, replace 0 with 1 */
  opacity: ${props => (props.expanded ? '0' : '0')};
  opacity: ${props => (props.parent && '0.5 !important')};
  transition: opacity 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      opacity: 0 !important;
    }
`;

const Item = styled.li`
  list-style: none;
  margin: 0;
  font-family: 'Nunito Sans', 'Helvetica Neue', 'Arial', 'sans-serif';
  a {
    white-space: nowrap;
  }
  &.logo{
    a{
      margin: 0;
    }
  }
  &.primary{
    display: flex;
    justify-content: center;
    @media only screen and (max-width: ${theme.dim.smallmobilebreakpoint}px){
      display: none;
    }
  }
  &.burger{
    color: ${props => props.linkcolor};
    display: none;
    height: 40px;
    font-weight: bold;
    cursor: pointer;
    @media only screen and (max-width: ${theme.dim.smallmobilebreakpoint}px){
      display: list-item;
    }
  }
`;

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
    };
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onMouseOver = (value) => {
    this.setState({
      expanded: value,
    });
  }

  onMouseOut = () => {
    this.setState({
      expanded: null,
    });
  }

  render() {
    const {
      siteTitle, burgerClick, parent, dark,
    } = this.props;
    const { expanded } = this.state;
    const linkcolor = dark ? 'black' : 'white';

    return (
      <TopHeader>
        <Container>
          <NavSeparator parent={parent === 'company'} expanded={expanded !== null} dark={dark} />
          <Navigation>
            <List>
              <Item className="logo">
                <Link to="/">
                  {
                    dark ? <TmrowLogoBlack width="140px" alt={siteTitle} /> : <TmrowLogo width="140px" alt={siteTitle} />
                  }
                </Link>
              </Item>
              <Item className="primary">
                <List>
                  <Item
                    onMouseOver={() => this.onMouseOver('company')}
                    onMouseOut={() => this.onMouseOut()}
                    onFocus={() => this.onMouseOver('company')}
                    onBlur={() => this.onMouseOut}
                  >
                    <Link
                      to="/"
                      linkcolor={linkcolor}
                    >
                      Home
                    </Link>
                  </Item>
                  <Item>
                    <Link
                      to="/blog/"
                      partiallyActive
                      linkcolor={linkcolor}
                    >
                      Blog
                    </Link>
                  </Item>
                  <Item>
                    <Link
                      to="/climatechange/"
                      linkcolor={linkcolor}
                    >
                      Climate Change
                    </Link>
                  </Item>
                  <Item>
                    <Link
                      to="https://electricitymaps.com"
                      linkcolor={linkcolor}
                    >
                      electricitymaps.com
                    </Link>
                  </Item>
                </List>
              </Item>
              <Item
                className="burger"
                onClick={burgerClick}
                linkcolor={linkcolor}
              >
                Menu
              </Item>
            </List>
          </Navigation>
        </Container>
      </TopHeader>
    );
  }
}

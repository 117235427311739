import EuclidCircularBBold from './EuclidCircularB-Semibold-WebS.woff';
import EuclidCircularBMedium from './EuclidCircularB-Medium-WebS.woff';
import EuclidCircularBRegular from './EuclidCircularB-Regular-WebS.woff';

import EuclidFlexBold from './EuclidFlex-Semibold-WebS.woff';
import EuclidFlexMedium from './EuclidFlex-Medium-WebS.woff';
import EuclidFlexRegular from './EuclidFlex-Regular-WebS.woff';

export default {
  EuclidCircularBRegular,
  EuclidCircularBMedium,
  EuclidCircularBBold,
  EuclidFlexRegular,
  EuclidFlexMedium,
  EuclidFlexBold,
};


import { createGlobalStyle } from 'styled-components';
import fontFiles from './fonts';

const fontStyles = createGlobalStyle`
  @font-face {
    font-family: "Euclid Circular B";
    font-style: normal;
    font-weight: 400;
    src: url(${fontFiles.EuclidCircularBRegular}) format("woff");
  }
  @font-face {
    font-family: "Euclid Circular B";
    font-style: normal;
    font-weight: 500;
    src: url(${fontFiles.EuclidCircularBMedium}) format("woff");
  }
  @font-face {
    font-family: "Euclid Circular B";
    font-style: normal;
    font-weight: 700;
    src: url(${fontFiles.EuclidCircularBBold}) format("woff");
  }
  @font-face {
    font-family: "Euclid Flex";
    font-style: normal;
    font-weight: normal;
    src: url(${fontFiles.EuclidFlexRegular}) format("woff");
  }
  @font-face {
    font-family: "Euclid Flex";
    font-style: normal;
    font-weight: bold;
    src: url(${fontFiles.EuclidFlexBold}) format("woff");
  }
`;

export default fontStyles;

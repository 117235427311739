import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/themeconstants';

import Link from '../elements/link';

const SlideOutNav = styled.div`
  padding-right: 1rem;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 5;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  transform: ${props => (props.mobileNavExpanded ? 'translateX(0px)' : 'translateX(100px)')};
`;

const List = styled.ul`
  list-style: none;
  margin-left: 0; 
`;

const Item = styled.li`
  color: rgba(22,20,40,1);
  font-family: 'Euclid Flex','Helvetica Neue','Arial',sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.55185rem;
  line-height: 1.1;
  @media (max-width: ${theme.dim.smallmobilebreakpoint}px) {
    font-size: 1rem;
  }
`;

const MobileNav = ({ mobileNavExpanded }) => (
  <SlideOutNav>
    <Container>
      <MenuContainer mobileNavExpanded={mobileNavExpanded}>
        <List>
          <Item>
              <Link mobilemenu="true" to="/">Home</Link>
          </Item>
          <Item>
              <Link mobilemenu="true" to="/blog/">Blog</Link>
          </Item>
          <Item>
              <Link mobilemenu="true" to="/climatechange/">Climate Change</Link>
          </Item>
          <Item>
              <Link mobilemenu="true" to="https://electricitymaps.com">electricitymaps.com</Link>
          </Item>

        </List>
      </MenuContainer>
    </Container>
  </SlideOutNav>
);

export default MobileNav;